/*

 __    __  ________  _______    ______         _______   __         ______    ______   __    __ 
/  |  /  |/        |/       \  /      \       /       \ /  |       /      \  /      \ /  |  /  |
$$ |  $$ |$$$$$$$$/ $$$$$$$  |/$$$$$$  |      $$$$$$$  |$$ |      /$$$$$$  |/$$$$$$  |$$ | /$$/ 
$$ |__$$ |$$ |__    $$ |__$$ |$$ |  $$ |      $$ |__$$ |$$ |      $$ |  $$ |$$ |  $$/ $$ |/$$/  
$$    $$ |$$    |   $$    $$< $$ |  $$ |      $$    $$< $$ |      $$ |  $$ |$$ |      $$  $$<   
$$$$$$$$ |$$$$$/    $$$$$$$  |$$ |  $$ |      $$$$$$$  |$$ |      $$ |  $$ |$$ |   __ $$$$$  \  
$$ |  $$ |$$ |_____ $$ |  $$ |$$ \__$$ |      $$ |__$$ |$$ |_____ $$ \__$$ |$$ \__/  |$$ |$$  \ 
$$ |  $$ |$$       |$$ |  $$ |$$    $$/       $$    $$/ $$       |$$    $$/ $$    $$/ $$ | $$  |
$$/   $$/ $$$$$$$$/ $$/   $$/  $$$$$$/        $$$$$$$/  $$$$$$$$/  $$$$$$/   $$$$$$/  $$/   $$/ 

*/
import { urlFor } from '@lib/sanity'
import { usePageType } from '@state/store'

import styles from './HeroBlock.module.scss'

const HeroBlock = ({ image, data, colourTheme }) => {
  const { theme, noTopPadding, noBottomPadding } = data || {}
  const pageType = usePageType()

  return image.asset ? (
    <section
      className={styles.container}
      data-theme={theme ? theme : 'light'}
      style={{ background: colourTheme, paddingBottom: noBottomPadding && '0',  paddingTop: noTopPadding && '0' }}
    >
      <div className={`${styles.imageWrap} ${pageType.getPageType() == 'landing' && styles.noGap}`}>
        <img
          src={urlFor(image.asset).quality(75).url()}
          alt={image.alt}
          sizes={
            '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
          }
        />
      </div>
    </section>
  ) : (
    <section className={`${styles.imageWrap} ${pageType.getPageType() == 'landing' && styles.noGap}`} style={{ background: colourTheme, paddingBottom: noBottomPadding && '0',  paddingTop: noTopPadding && '0' }}>
      <div className="relative">
        <img
          src={`/img/fs1.webp`}
          alt={image.alt}
          sizes={
            '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
          }
        />
      </div>
    </section>
  )
}

export default HeroBlock
