import useIsTouchDevice from '@hooks/useIsTouchDevice'
import { urlFor } from '@lib/sanity'
import Image from 'next/image'
import Link from 'next/link'

import styles from './ProjectCard.module.scss'

export default function ProjectCard({ content }) {
  const { title, slug, desc, image } = content

  return (
    <div key={slug.current} className={styles.wrapper}>
      {image && (
        <div className={styles.imageWrapper}>
          <Link href={slug} title={title} prefetch={false}>
            <ProjectCardImage
              image={image.asset}
              alt={title}
              height={[500, 600]}
            />
          </Link>
        </div>
      )}
      <div className={styles.detailsWrapper}>
        <h3>{title}</h3>
        {desc && <p>{desc}</p>}
        <Link href={slug} title={title} prefetch={false}>
          View project
        </Link>
      </div>
    </div>
  )
}

const ProjectCardImage = ({ image, alt, height }) => {
  let isTouchDevice = useIsTouchDevice()
  let h = 0

  if (isTouchDevice) {
    h = height[0]
  } else {
    h = height[1]
  }

  return (
    <div style={{ height: h }}>
      <Image
        src={urlFor(image)
          .crop('entropy')
          .fit('scale')
          .quality(90)
          .format('webp')
          .url()}
        alt={alt}
        className={styles.image}
        fill
        sizes={
          '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
        }
      />
    </div>
  )
}
