import { SearchBox } from 'react-instantsearch-hooks-web';

import styles from './SearchBar.module.scss'


export const SearchBar = () => {

    return(
        <div className={styles.container}>
        <SearchBox
              placeholder="Something you’re looking for?"
              classNames={{
                root: `${styles.wrapper}`,
                form: `${styles.bar}`,
                input: `${styles.searcher}`
              }}
            />
        </div>
    )
}