import { TextPortable } from '@lib/sanity'
import Image from 'next/image'

import styles from './ShopifyPartner.module.scss'

const ShopifyPartner = ({ data, text }) => {
  const { flow, theme } = data || {}

  return (
    <div
      className={`${styles.container} ${styles[flow]}`}
      data-theme={theme ? theme : 'light'}
    >
      <section
        className={`${styles.wrapper} ${styles[theme]} flex-[2]`}
        data-theme={theme ? theme : 'light'}
      >
        {text && <TextPortable blocks={text} />}
      </section>
      <section className={styles.badgeWrapper}>
        <Image
          src="/img/shopify-partner.png"
          alt="Shopify Partners"
          width={225}
          height={115}
        />
      </section>
    </div>
  )
}

export default ShopifyPartner
