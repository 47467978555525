/*
====================================================================================================== 
 ______                                                    _______   __                      __       
/      |                                                  /       \ /  |                    /  |      
$$$$$$/  _____  ____    ______    ______    ______        $$$$$$$  |$$ |  ______    _______ $$ |   __ 
  $$ |  /     \/    \  /      \  /      \  /      \       $$ |__$$ |$$ | /      \  /       |$$ |  /  |
  $$ |  $$$$$$ $$$$  | $$$$$$  |/$$$$$$  |/$$$$$$  |      $$    $$< $$ |/$$$$$$  |/$$$$$$$/ $$ |_/$$/ 
  $$ |  $$ | $$ | $$ | /    $$ |$$ |  $$ |$$    $$ |      $$$$$$$  |$$ |$$ |  $$ |$$ |      $$   $$<  
 _$$ |_ $$ | $$ | $$ |/$$$$$$$ |$$ \__$$ |$$$$$$$$/       $$ |__$$ |$$ |$$ \__$$ |$$ \_____ $$$$$$  \ 
/ $$   |$$ | $$ | $$ |$$    $$ |$$    $$ |$$       |      $$    $$/ $$ |$$    $$/ $$       |$$ | $$  |
$$$$$$/ $$/  $$/  $$/  $$$$$$$/  $$$$$$$ | $$$$$$$/       $$$$$$$/  $$/  $$$$$$/   $$$$$$$/ $$/   $$/ 
                                /  \__$$ |                                                            
                                $$    $$/                                                             
                                 $$$$$$/              
                             
Renders an image block from the page builder                                 
====================================================================================================== 
*/
import { urlFor } from '@lib/sanity'
import Image from 'next/image'

const ImageBlock = ({ image, data }) => {
  const { theme } = data || {}

  return (
    <section data-theme={theme ? theme : 'light'}>
      <Image
        src={urlFor(image.asset).quality(75).format('webp').url()}
        alt={image.alt}
        width="100"
        height="100"
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    </section>
  )
}

export default ImageBlock
