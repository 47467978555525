import { urlFor } from '@lib/sanity'
import Image from 'next/image'
import Link from 'next/link'

import styles from './CareersCard.module.scss'

const CareersCard = ({content}) => {

    const { title, slug, image, desc } = content || {}

    // console.log(content)

    const ProjectCardImage = ({ image, alt}) => {
   
        return (
          <Image
            src={urlFor(image)
              .crop('focalpoint')
              .fit('crop')
              .width(300)
              .height(175)
              .quality(90)
              .dpr(1)
              .sharpen(12)
              .format('webp')
              .url()}
            alt={alt}
            className="object-cover object-center"
            fill
          />
        )
      }

  return (
    <div key={slug.current} className={styles.wrapper}>
      {image && (
        <div className={styles.imageWrapper}>
          <div className={styles.image}>
          <ProjectCardImage
            image={image.asset}
            alt={title}
            height={[500, 800]}
          />
          </div>
        </div>
      )}
      <div className={styles.detailsWrapper}>
        <h3>{title}</h3>
        {desc && <p>{desc}</p> }
        <Link href={slug} title={title} prefetch={false}>
          View position
        </Link>
      </div>
    </div>
  )

}

export default CareersCard