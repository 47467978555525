import { useRefinementList } from 'react-instantsearch-hooks-web'

import styles from './Filters.module.scss'

export function Filters(props) {
  const { items, refine } = useRefinementList(props)

  // console.log('Items: ', items)

  return (
    <div className={styles.container}>
      {items.map((item, i) => (
        <div key={i}>
          <label className={styles.filterAction}>
            <input
              type="checkbox"
              value={item.value}
              checked={item.isRefined}
              onChange={() => refine(item.value)}
            />
            <span
              className={`${
                item.isRefined && styles.active
              } ais-RefinementList-labelText`}
            >
              {item.label}
            </span>
          </label>
        </div>
      ))}
    </div>
  )
}
