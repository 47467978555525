/*
=============================================================================================================================================================
_______                                                    __        __                   ______             __                                             
/       \                                                  /  |      /  |                 /      \           /  |                                            
$$$$$$$  |  ______   ______    ______    ______    ______  $$ |____  $$ |  ______        /$$$$$$  |  ______  $$ | __    __  _____  ____   _______    _______ 
$$ |  $$ | /      \ /      \  /      \  /      \  /      \ $$      \ $$ | /      \       $$ |  $$/  /      \ $$ |/  |  /  |/     \/    \ /       \  /       |
$$ |  $$ |/$$$$$$  |$$$$$$  |/$$$$$$  |/$$$$$$  | $$$$$$  |$$$$$$$  |$$ |/$$$$$$  |      $$ |      /$$$$$$  |$$ |$$ |  $$ |$$$$$$ $$$$  |$$$$$$$  |/$$$$$$$/ 
$$ |  $$ |$$ |  $$/ /    $$ |$$ |  $$ |$$ |  $$ | /    $$ |$$ |  $$ |$$ |$$    $$ |      $$ |   __ $$ |  $$ |$$ |$$ |  $$ |$$ | $$ | $$ |$$ |  $$ |$$      \ 
$$ |__$$ |$$ |     /$$$$$$$ |$$ \__$$ |$$ \__$$ |/$$$$$$$ |$$ |__$$ |$$ |$$$$$$$$/       $$ \__/  |$$ \__$$ |$$ |$$ \__$$ |$$ | $$ | $$ |$$ |  $$ | $$$$$$  |
$$    $$/ $$ |     $$    $$ |$$    $$ |$$    $$ |$$    $$ |$$    $$/ $$ |$$       |      $$    $$/ $$    $$/ $$ |$$    $$/ $$ | $$ | $$ |$$ |  $$ |/     $$/ 
$$$$$$$/  $$/       $$$$$$$/  $$$$$$$ | $$$$$$$ | $$$$$$$/ $$$$$$$/  $$/  $$$$$$$/        $$$$$$/   $$$$$$/  $$/  $$$$$$/  $$/  $$/  $$/ $$/   $$/ $$$$$$$/  
                             /  \__$$ |/  \__$$ |                                                                                                            
                             $$    $$/ $$    $$/                                                                                                             
                              $$$$$$/   $$$$$$/            
                              
Renders the Draggable Columns block from the page builder, usinf the swiper api
=============================================================================================================================================================
*/

import 'swiper/css'
import 'swiper/css/pagination'

import { A11y, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './DraggableColumns.module.scss'

const DraggableColumns = ({ title, columns, data }) => {
  const { theme } = data || {}
  return (
    <div className={styles.wrapper} data-theme={theme ? theme : 'dark'}>
      <section className={styles.container}>
        {title && <h2>{title}</h2>}

        <Swiper
          modules={[A11y, Pagination]}
          pagination={{ clickable: true, type: 'progressbar' }}
          className={styles.dragPane}
          slidesPerView={1.2}
          spaceBetween={30}
          freeMode={true}
          breakpoints={{
            620: {
              slidesPerView: 2.5,
              spaceBetween: 60,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 80,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 100,
            },
          }}
        >
          {columns.map((node, i) => {
            const { title, text } = node
            return (
              <SwiperSlide key={i}>
                <h4>{title}</h4>
                <p>{text}</p>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </section>
    </div>
  )
}

export default DraggableColumns
