/*
==========================================================================================================
______                         __                            __             ______   ________  ______  
 /      \                       /  |                          /  |           /      \ /        |/      \ 
/$$$$$$  |  ______   _______   _$$ |_     ______    _______  _$$ |_         /$$$$$$  |$$$$$$$$//$$$$$$  |
$$ |  $$/  /      \ /       \ / $$   |   /      \  /       |/ $$   |        $$ |  $$/    $$ |  $$ |__$$ |
$$ |      /$$$$$$  |$$$$$$$  |$$$$$$/    $$$$$$  |/$$$$$$$/ $$$$$$/         $$ |         $$ |  $$    $$ |
$$ |   __ $$ |  $$ |$$ |  $$ |  $$ | __  /    $$ |$$ |        $$ | __       $$ |   __    $$ |  $$$$$$$$ |
$$ \__/  |$$ \__$$ |$$ |  $$ |  $$ |/  |/$$$$$$$ |$$ \_____   $$ |/  |      $$ \__/  |   $$ |  $$ |  $$ |
$$    $$/ $$    $$/ $$ |  $$ |  $$  $$/ $$    $$ |$$       |  $$  $$/       $$    $$/    $$ |  $$ |  $$ |
 $$$$$$/   $$$$$$/  $$/   $$/    $$$$/   $$$$$$$/  $$$$$$$/    $$$$/         $$$$$$/     $$/   $$/   $$/ 

 Renders the 'contact CTA' page builder block type
==========================================================================================================
*/

import Button from '@components/Button'
import CTA from '@components/CTA'
import addBreak from '@utils/addBreak'
import Link from 'next/link'

import styles from './ContactCTA.module.scss'

export default function ContactCTA({ data }) {
  const { title, text, cta, theme } = data || {
    title: 'Book your free discovery call',
    text: 'Start a project 🚧',
    secondLink: {
      text: 'Book a call',
      url: '/contact',
    },
    bg: 'dark',
  }

  return (
    <div
      className={`${styles.wrapper} ${styles[theme]}`}
      data-theme={theme ? theme : 'dark'}
    >
      <section className={styles.container}>
        {title && <h2 className={styles.title}>{addBreak(title, 2)}</h2>}

        <Link href="/contact" title="Contact" prefetch={false}>
          <Button type="button" text={text} scrolling opt={'light'} />
        </Link>

        {cta && (
          <div className={`${styles.extraLink} ${theme == 'light' ? styles.lightUnder : styles.darkUnder} mt-8`}>
           <CTA content={cta} opt={cta.theme} />
          </div>
        )}
      </section>
    </div>
  )
}
