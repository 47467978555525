import CareersCard from '@components/CareersCard'
import NewsletterBreaker from '@components/NewsletterBreaker'
import { TextPortable } from '@lib/sanity'

import styles from './CareersFeed.module.scss'

const CareersFeed = ({data}) => {
    const {theme, title, description, jobs} = data || {}
    return (
        <div
        className={`${styles.wrapper} ${styles[theme]}`}
        data-theme={theme ? theme : 'light'}
      >
        <section className={styles.container}>
          {title && <h2>{title}</h2>}
          {description && <div className={styles.desc}>{<TextPortable value={description} />}</div>}
  
        
          <div className={styles.inner}>
                <div className={styles.list}>
                {jobs.map((node, i) => {
               return <CareersCard key={i} content={node} index={i} />
            })}
                </div>
                <div className={styles.sidebar}>
                  <div className={styles.stick}>
                    <NewsletterBreaker />
                  </div>
                </div>
              </div>
              
        </section>
      </div>
    )
}

export default CareersFeed