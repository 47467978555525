import CTA from '@components/CTA'
import HeroReviews from '@components/HeroReviews'
import { urlFor } from '@lib/sanity'
import addBreak from '@utils/addBreak'
import Image from 'next/image'

import styles from './HomeHero.module.scss'

export default function GenericHero({ data }) {
  const { mobile, desktop } = data?.subtitle || {}
  const { image, fitContent, theme, logo } = data || {}

  return (
    <div
      className={`${styles.wrapper} ${fitContent && styles.shallow} ${styles[theme]}`}
      data-theme={theme ? theme : 'tertiary'}
    >
      <section className={styles.container}>
        <div>&nbsp;</div>

        <div className={styles.content}>
          {logo && <div className={styles.logo}> <Image
                src="/img/karmoon-logo.svg"
                alt={data?.image.alt}
                width={100}
                priority={true}
                height={100}
              /> </div>}
          {data?.title && <h1>{addBreak(data.title, 2)}</h1>}
          <h2>
            {mobile && <span>{mobile}</span>}
            {desktop && <span>{desktop}</span>}
          </h2>
          {data?.introduction && <p>{data.introduction}</p>}
          {image.asset && (
            <div className={styles.bagMan}>
              <Image
                src={urlFor(data.image.asset)
                  .width(450)
                  .dpr(1)
                  .sharpen(12)
                  .format('webp')
                  .url()}
                alt={data?.image.alt}
                width={400}
                priority={true}
                height={600}
              />
            </div>
          )}
          {data?.cta && <CTA content={data.cta} opt={'light'} />}
        </div>

        <HeroReviews data={data?.reviews} />
      </section>
    </div>
  )
}
