/*
███████╗███████╗ █████╗ ████████╗██╗   ██╗██████╗ ███████╗██████╗ 
██╔════╝██╔════╝██╔══██╗╚══██╔══╝██║   ██║██╔══██╗██╔════╝██╔══██╗
█████╗  █████╗  ███████║   ██║   ██║   ██║██████╔╝█████╗  ██║  ██║
██╔══╝  ██╔══╝  ██╔══██║   ██║   ██║   ██║██╔══██╗██╔══╝  ██║  ██║
██║     ███████╗██║  ██║   ██║   ╚██████╔╝██║  ██║███████╗██████╔╝
╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═════╝ 
██████╗ ██████╗  ██████╗      ██╗███████╗ ██████╗████████╗███████╗
██╔══██╗██╔══██╗██╔═══██╗     ██║██╔════╝██╔════╝╚══██╔══╝██╔════╝
██████╔╝██████╔╝██║   ██║     ██║█████╗  ██║        ██║   ███████╗
██╔═══╝ ██╔══██╗██║   ██║██   ██║██╔══╝  ██║        ██║   ╚════██║
██║     ██║  ██║╚██████╔╝╚█████╔╝███████╗╚██████╗   ██║   ███████║
╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚════╝ ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝
*/
// NOTE: Renders the featured work / projects block from the page builder.
// NOTE: Also used on the dedicated work page

import Button from '@components/Button'
import ProjectCard from '@components/ProjectCard'
import { TextPortable } from '@lib/sanity'
import { usePageType } from '@state/store'
import Link from 'next/link'

import styles from './FeaturedProjects.module.scss'

const FeaturedProjects = ({ projects, data }) => {

  const { theme, title, body } = data || {}
  const pageType = usePageType()

  // console.log(data)

  return (
    <div
      className={`${styles.wrapper} ${styles[theme]}`}
      data-theme={theme ? theme : 'light'}
    >
      <section className={styles.container}>
        {title && <h2>{title}</h2>}
        {body && <div className={styles.desc}><TextPortable blocks={body} /></div>}
        <div className={styles.projectList}>
          {projects.map((node, i) => {
            return <ProjectCard key={i} content={node} index={i} />
          })}
        </div>
        {pageType.getPageType() == "work" ? null :
        <div className="flex items-center justify-center mt-20">
        <Link href="/work" title="View all work" prefetch={false}>
          <Button text="View all Work  🔍" opt={'light'} outline />
        </Link>
      </div> 
      }
      </section>
    </div>
  )
}

export default FeaturedProjects
