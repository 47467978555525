import CTA from '@components/CTA'
import { TextPortable } from '@lib/sanity'

import styles from './StatBlock.module.scss'

const StatBlock = ({ data, text, colourTheme, colourText }) => {
  const { title, cta, stat, theme } = data || {}
  const { isEnabled, theme: _theme } = cta || {}

  const determienStat = (s) => {
    if (s.statType == 'speed') {
      return (
        <div className={styles.statBox}>
          <div style={{ background: colourTheme }} className={styles.stat}>
            <div className={styles.statRing}>
              <span>{s.percentage}</span>
            </div>
            <div className={styles.statInfo}>
              <span>Speed score</span>
              {/* <span>Your store is faster than similar stores on Shopify.</span> */}
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <section
      className={`${styles.outerWrap} ${styles[colourText]}`}
      data-theme={theme ? theme : 'light'}
      style={{ background: colourTheme }}
    >
      {title && <h4>{title}</h4>}
      <div className={`${styles.wrapper}`}>
        {text && (
          <div>
            <TextPortable blocks={text} />
          </div>
        )}
        {stat && determienStat(stat)}
      </div>

      {isEnabled && (
        <div className="flex w-full justify-center mt-10">
          <CTA content={cta} opt={_theme} />
        </div>
      )}
    </section>
  )
}

export default StatBlock
