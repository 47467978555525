import { Filters } from './Filters'
import { SearchBar } from './SearchBar'
// import { Sort } from './Sort'
import styles from './UtilityBar.module.scss'

// The bar at the top of the article feed. Contains all filters, search bar and sort by
const UtilityBar = ({ filterBy }) => {
  return (
    <div className={styles.wrapper}>
      <Filters attribute={filterBy} />
      <SearchBar placeholder="Something you're looking for?" />
      {/* uncomment below to include sort by filters */}
      {/* <Sort /> */}
    </div>
  )
}

export default UtilityBar
