/*
====================================================================================================== 
 __       __                  __  __                  _______   __                      __                                                       
/  \     /  |                /  |/  |                /       \ /  |                    /  |                                                      
$$  \   /$$ |  ______    ____$$ |$$/   ______        $$$$$$$  |$$ |  ______    _______ $$ |   __                                                 
$$$  \ /$$$ | /      \  /    $$ |/  | /      \       $$ |__$$ |$$ | /      \  /       |$$ |  /  |                                                
$$$$  /$$$$ |/$$$$$$  |/$$$$$$$ |$$ | $$$$$$  |      $$    $$< $$ |/$$$$$$  |/$$$$$$$/ $$ |_/$$/                                                 
$$ $$ $$/$$ |$$    $$ |$$ |  $$ |$$ | /    $$ |      $$$$$$$  |$$ |$$ |  $$ |$$ |      $$   $$<                                                  
$$ |$$$/ $$ |$$$$$$$$/ $$ \__$$ |$$ |/$$$$$$$ |      $$ |__$$ |$$ |$$ \__$$ |$$ \_____ $$$$$$  \                                                 
$$ | $/  $$ |$$       |$$    $$ |$$ |$$    $$ |      $$    $$/ $$ |$$    $$/ $$       |$$ | $$  |                                                
$$/      $$/  $$$$$$$/  $$$$$$$/ $$/  $$$$$$$/       $$$$$$$/  $$/  $$$$$$/   $$$$$$$/ $$/   $$/                                                 
                             
Renders a media block from the page builder                                 
====================================================================================================== 
*/
import { urlFor } from '@lib/sanity'

// import Image from 'next/image'
import styles from './MediaBlock.module.scss'

const MediaBlock = ({ data, colourTheme, colourText }) => {
  // console.log(data)
  // either renders an image or a video

  const {
    mediaType,
    // video,
    // mobileVdeo,
    image,
    paddingX,
    noTopPadding,
    noBottomPadding,
    // mobileImage,
    theme,
  } = data || {}
  // console.log(image.asset)


  const generateImage = () => {
    return image ? (
      <div className={styles.imageWrapper} style={paddingX && {padding: `0 ${paddingX.toString()}px`}}>
        <img
          src={urlFor(image.asset)
            .format('webp')
            .quality(100)
            .url()}
          alt={image.alt}
          style={{
            maxWidth: '100%',
            height: '100%'
          }}
        />
      </div>
    ) : (
      <div className={styles.imageWrapper}>
        <img
          src="/img/placeholder1.webp"
          alt="test"
          // fill
          // objectFit="contain"
        />
      </div>
    )
  }

  if (mediaType == 'image') {
    return (
      <section
        className={`${styles.container} ${styles[theme]}`}
        style={{ background: colourTheme, paddingBottom: noBottomPadding && '0',  paddingTop: noTopPadding && '0' }}
        data-theme={
          colourText && colourText == 'light'
            ? 'dark'
            : colourText && colourText == 'dark'
            ? 'light'
            : 'light'
        }
      >
        {generateImage()}
      </section>
    )
  } else {
    return <div>
      video
    </div>
  }
}

export default MediaBlock
