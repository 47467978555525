/*
██████╗  █████╗  ██████╗ ███████╗                   
██╔══██╗██╔══██╗██╔════╝ ██╔════╝                   
██████╔╝███████║██║  ███╗█████╗                     
██╔═══╝ ██╔══██║██║   ██║██╔══╝                     
██║     ██║  ██║╚██████╔╝███████╗                   
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝                   
██████╗ ██╗   ██╗██╗██╗     ██████╗ ███████╗██████╗ 
██╔══██╗██║   ██║██║██║     ██╔══██╗██╔════╝██╔══██╗
██████╔╝██║   ██║██║██║     ██║  ██║█████╗  ██████╔╝
██╔══██╗██║   ██║██║██║     ██║  ██║██╔══╝  ██╔══██╗
██████╔╝╚██████╔╝██║███████╗██████╔╝███████╗██║  ██║
╚═════╝  ╚═════╝ ╚═╝╚══════╝╚═════╝ ╚══════╝╚═╝  ╚═╝
*/

import { useAppContext } from '@hooks/useAppContext'

// import { data } from 'autoprefixer'
// import dynamic from 'next/dynamic'
import Accordion from './Accordion'
import ArticleFeed from './ArticleFeed'
import Calendly from './Calendly'
import CareersFeed from './CareersFeed'
import ContactCTA from './ContactCTA'
import DraggableColumns from './DraggableColumns'
import FAQ from './FAQ'
import FeaturedProjects from './FeaturedProjects'
import GenericHero from './GenericHero'
import HeroBlock from './HeroBlock'
import HighlightBox from './HighlightBox'
import HomeHero from './HomeHero'
import ImageBlock from './ImageBlock'
import ImageGrid from './ImageGrid'
import ImageTextBlock from './ImageTextBlock'
import MediaBlock from './MediaBlock'
import ProjectExplorer from './ProjectExplorer'
import RecentArticles from './RecentArticles'
import Reviews from './Reviews'
import ScrollingImages from './ScrollingImages'
import ShopifyPartner from './ShopifyPartner'
import ShowReel from './ShowReel'
import Spacer from './Spacer'
import StatBlock from './StatBlock'
import TeamCards from './TeamCards'
import TextBlock from './TextBlock'
import TextColumns from './TextColumns'
import VideoBlock from './VideoBlock'

// const RecentArticles = dynamic(() => import('./RecentArticles'), { ssr: false })
// const FeaturedProjects = dynamic(() => import('./FeaturedProjects'), {
//   ssr: false,
// })
// const DraggableColumns = dynamic(() => import('./DraggableColumns'), {
//   ssr: false,
// })
// const ImageBlock = dynamic(() => import('./ImageBlock'), { ssr: false })
// const ImageGrid = dynamic(() => import('./ImageGrid'), { ssr: false })
// const Spacer = dynamic(() => import('./Spacer'), { ssr: false })
// const HeroBlock = dynamic(() => import('./HeroBlock'), { ssr: false })
// const TextBlock = dynamic(() => import('./TextBlock'), { ssr: false })
// const ShopifyPartner = dynamic(() => import('./ShopifyPartner'), { ssr: false })
// const StatBlock = dynamic(() => import('./StatBlock'), { ssr: false })
// const ImageTextBlock = dynamic(() => import('./ImageTextBlock'), { ssr: false })
// const TextColumns = dynamic(() => import('./TextColumns'), { ssr: false })
// const ContactCTA = dynamic(() => import('./ContactCTA'), { ssr: false })
// const Accordion = dynamic(() => import('./Accordion'), { ssr: false })
// const ArticleFeed = dynamic(() => import('./ArticleFeed'), { ssr: false })
// const FAQ = dynamic(() => import('./FAQ'), { ssr: false })


// const HomeHero = dynamic(() => import('./HomeHero'), { ssr: false })
// const GenericHero = dynamic(() => import('./GenericHero'), { ssr: false })
// const HighlightBox = dynamic(() => import('./HighlightBox'), { ssr: false })
// const ProjectExplorer = dynamic(() => import('./ProjectExplorer'), {
//   ssr: false,
// })
// const TeamCards = dynamic(() => import('./TeamCards'), { ssr: false })
// const CareersFeed = dynamic(() => import('./CareersFeed'), { ssr: false })

// const MediaBlock = dynamic(() => import('./MediaBlock'), { ssr: false })
// const Calendly = dynamic(() => import('./Calendly'), { ssr: false })
// const ScrollingImages = dynamic(() => import('./ScrollingImages'), {
//   ssr: false,
// })
// const Reviews = dynamic(() => import('./Reviews'), { ssr: false })
// const ShowReel = dynamic(() => import('./ShowReel'), { ssr: false })

const Block = ({ index, block, colourTheme, colourText }) => {
  const type = block._type
  const { globals } = useAppContext()
  const site = globals.site

  switch (type) {
    case 'showreel':
      return <ShowReel index={index} data={block} />
    case 'spacerBlock':
      return <Spacer index={index} data={block} />
    case 'homeHeroBlock':
      return <HomeHero index={index} data={block} />
    case 'pageHeroBlock':
      return <GenericHero index={index} data={block} />
    case 'contactCta':
      return <ContactCTA index={index} data={block} text={block.text} />
    case 'video':
      return <VideoBlock index={index} data={block} />
    case 'draggableColumns':
      return (
        <DraggableColumns
          index={index}
          data={block}
          title={block.title}
          columns={block.columns}
        />
      )
    case 'featuredProjects':
      return (
        <FeaturedProjects
          index={index}
          data={block}
          title={block.title}
          projects={block.projects}
        />
      )
    case 'imageBlock':
      return (
        <ImageBlock
          index={index}
          data={block}
          image={block.image}
          colourTheme={colourTheme}
          colourText={colourText}
        />
      )
    case 'imageGrid':
      return (
        <ImageGrid
          index={index}
          data={block}
          colourTheme={colourTheme}
          colourText={colourText}
        />
      )
    case 'highlightBox':
      return <HighlightBox index={index} data={block} />
    case 'heroBlock':
      return (
        <HeroBlock
          index={index}
          data={block}
          image={block.image}
          colourText={colourText}
          colourTheme={colourTheme}
        />
      )
    case 'calendlyBlock':
      return <Calendly index={index} data={block} url={site.social} />
    case 'faq':
      return <FAQ index={index} data={block} />
    case 'imageTextBlock':
      return (
        <ImageTextBlock
          index={index}
          data={block}
          image={block.image}
          text={block.text}
        />
      )
    case 'recentArticles':
      return <RecentArticles index={index} data={block} title={block.title} />
      case 'careersFeed':
        return <CareersFeed index={index} data={block} />
    case 'textBlock':
      return <TextBlock index={index} data={block} text={block.text} />
    case 'shopifyPartner':
      return <ShopifyPartner index={index} data={block} text={block.text} />
    case 'stats':
      return (
        <StatBlock
          index={index}
          data={block}
          text={block.text}
          colourTheme={colourTheme}
          colourText={colourText}
        />
      )
    case 'textColumns':
      return <TextColumns index={index} data={block} />
    case 'accordion':
      return <Accordion index={index} data={block} />
    case 'articleFeed':
      return <ArticleFeed index={index} data={block} />
    case 'mediaBlock':
      return (
        <MediaBlock
          index={index}
          data={block}
          colourTheme={colourTheme}
          colourText={colourText}
        />
      )
    case 'Divider':
      return null
    // return <Divider index={index} data={block} />
    case 'HighlightBox':
      return <HighlightBox index={index} data={block} />
    case 'projectExplorer':
      return (
        <ProjectExplorer index={index} data={block} projects={data.projects} />
      )
    case 'teamCards':
      return <TeamCards index={index} data={block} />
    case 'scrollingImages':
      return <ScrollingImages index={index} data={block} colourTheme={colourTheme} />
    case 'reviews':
      return <Reviews index={index} data={block} />
    default:
      return null
  }
}

export default Block
