// import dynamic from 'next/dynamic'

import NewsletterForm from '@components/forms/NewsletterForm'

import styles from './NewsletterBreaker.module.scss'

// const NewsletterForm = dynamic(
//   () => import('@components/forms/NewsletterForm'),
//   { ssr: false }
// )

export default function NewsletterBreaker({type}) {
  return (
    <div className={`${styles.wrapper} ${styles[type]}`}>
      <h4 className="my-0 mx-auto p-0 text-[22px] text-center font-bold">
        Get access to exclusive updates, the latest news and the inside scoop 🍨
      </h4>

      <NewsletterForm form={'simple'} />
    </div>
  )
}
