/*
============================================================================================================
__    __  __            __        __  __            __          __            _______                      
/  |  /  |/  |          /  |      /  |/  |          /  |        /  |          /       \                     
$$ |  $$ |$$/   ______  $$ |____  $$ |$$/   ______  $$ |____   _$$ |_         $$$$$$$  |  ______   __    __ 
$$ |__$$ |/  | /      \ $$      \ $$ |/  | /      \ $$      \ / $$   |        $$ |__$$ | /      \ /  \  /  |
$$    $$ |$$ |/$$$$$$  |$$$$$$$  |$$ |$$ |/$$$$$$  |$$$$$$$  |$$$$$$/         $$    $$< /$$$$$$  |$$  \/$$/ 
$$$$$$$$ |$$ |$$ |  $$ |$$ |  $$ |$$ |$$ |$$ |  $$ |$$ |  $$ |  $$ | __       $$$$$$$  |$$ |  $$ | $$  $$<  
$$ |  $$ |$$ |$$ \__$$ |$$ |  $$ |$$ |$$ |$$ \__$$ |$$ |  $$ |  $$ |/  |      $$ |__$$ |$$ \__$$ | /$$$$  \ 
$$ |  $$ |$$ |$$    $$ |$$ |  $$ |$$ |$$ |$$    $$ |$$ |  $$ |  $$  $$/       $$    $$/ $$    $$/ /$$/ $$  |
$$/   $$/ $$/  $$$$$$$ |$$/   $$/ $$/ $$/  $$$$$$$ |$$/   $$/    $$$$/        $$$$$$$/   $$$$$$/  $$/   $$/ 
              /  \__$$ |                  /  \__$$ |                                                        
              $$    $$/                   $$    $$/                                                         
               $$$$$$/                     $$$$$$/                       
               
Renders the Highlight box block from the page builder. Can be used to display any kind of info
============================================================================================================
*/
import { urlFor } from '@lib/sanity'
import addBreak from '@utils/addBreak'
import Image from 'next/image'

import styles from './HighlightBox.module.scss'

const HighlightBox = ({ data }) => {
  const { theme, title, description, logoList } = data || {}

  // console.log(data)

  return (
    <div className={styles.wrapper} data-theme={theme ? theme : 'dark'}>
      <section className={styles.container}>
        {title && <h2>{addBreak(title, 2)}</h2>}
        {description && <p className={styles.desc}>{description}</p>}

        <div className={styles.iconGrid}>
          {logoList &&
            logoList.map((logo, i) => {
              return (
                <div key={i} className={styles.iconGrid__item}>
                  <Image
                    src={urlFor(logo.clientLogo.asset)
                      .quality(75)
                      .format('webp')
                      .url()}
                    alt={logo.clientName}
                    fill
                  />
                </div>
              )
            })}
        </div>
      </section>
    </div>
  )
}

export default HighlightBox
