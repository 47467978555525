export default function addBreak(str, count) {
  const words = str.split(' ')
  const first = words.slice(0, count).join(' ')
  const second = words.slice(count).join(' ')

  return (
    <>
      {first}
      <br />
      {second}
    </>
  )
}
