import CTA from '@components/CTA'
import HeroReviews from '@components/HeroReviews'
// import { BagMan } from '@components/Icons'
import { urlFor } from '@lib/sanity'
import addBreak from '@utils/addBreak'
// import dynamic from 'next/dynamic'
import Image from 'next/image'

import styles from './HomeHero.module.scss'

// const CTA = dynamic(() => import('@components/CTA'))
// const HeroReviews = dynamic(() => import('@components/HeroReviews'))

export default function HomeHero({ data }) {
  const { mobile, desktop } = data?.subtitle || {}

  return (
    <div className={styles.wrapper} data-theme={'light'}>
      <section className={styles.container}>
        <div>&nbsp;</div>

        <div className={styles.content}>
          {data?.title && <h1>{addBreak(data.title, 2)}</h1>}
          <h2>
            {mobile && <span>Shopify experts</span>}
            {desktop && <span>Shopify and Big Commerce experts</span>}
          </h2>
          {data?.introduction && <p>{data.introduction}</p>}
          {data?.image && (
            <div className={styles.bagMan}>
              <Image
                src={urlFor(data?.image.asset)
                  .width(450)
                  .dpr(1)
                  .sharpen(12)
                  .format('webp')
                  .url()}
                alt={data?.image.alt}
                width={400}
                priority={true}
                height={600}
              />
              {/* <BagMan /> */}
            </div>
          )}
          {data?.cta && <CTA content={data.cta} opt={'light'} />}
        </div>

        <HeroReviews data={data?.reviews} />
      </section>
    </div>
  )
}
