import NewsletterBreaker from '@components/NewsletterBreaker'
import { urlFor } from '@lib/sanity'
import Image from 'next/image'
import Link from 'next/link'
import { useHits } from 'react-instantsearch-hooks-web'

import styles from './Results.module.scss'

export const Results = (props) => {
  const { hits } = useHits(props)

  const template = {
    isSignup: true,
  }

  const insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ]

  const determinePos = () => {
    if (props.signupLocation == 'last') {
      return props.perPage
    } else {
      return props.signupLocation
    }
  }

  let feed = [...hits]
  let posts = insert(feed, determinePos() || 4, template)

  const reveal = () => {
    let reveals = document.querySelectorAll('.reveal')

    for (var i = 0; i < reveals.length; i++) {
      let windowHeight = window.innerHeight
      let elementTop = reveals[i].getBoundingClientRect().top
      let elementVisible = 150

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add('active')
      } else {
        reveals[i].classList.remove('active')
      }
    }
  }

  if (props.animateOnScroll) {
    window.addEventListener('scroll', reveal)
  }

  return (
    <div className="grid grid-cols-1 gap-x-16 gap-y-28 md:grid-cols-2 xl:grid-cols-3">
      {posts.map((hit, i) => {
        const aspects = [
          'aspect-article-one',
          'aspect-article-four',
          'aspect-article-five',
        ]

        // const padding = ['mt-4', '-mt-8', 'mt-12']

        const aspect = aspects[i % aspects.length]
        // const pad = padding[i % padding.length]

        if (Object.prototype.hasOwnProperty.call(hit, 'isSignup')) {
          return (
            <div
              key={hit.objectID}
              className={props.animateOnScroll && 'reveal'}
            >
              <NewsletterBreaker key={i} type="article" />
            </div>
          )
        } else {
          return (
            <div
              key={hit.objectID}
              className={props.animateOnScroll && 'reveal'}
            >
              <Link
                href={hit.slug && hit.slug}
                title="Insights"
                prefetch={false}
              >
                <article className={styles.wrapper}>
                  {hit.mainImage && (
                    <div className={`${styles.image} ${aspect}`}>
                      <Image
                        src={urlFor(hit.mainImage)
                          .crop('focalpoint')
                          .fit('fill')
                          .quality(100)
                          .height(450)
                          .format('webp')
                          .url()}
                        alt={hit.title}
                        fill
                        sizes="100vw"
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  )}
                  {/* <div className={styles.meta}>
                      <span>
                        {prettyDate} ― {hit.readingTimeInMinutes} min read
                      </span>
                    </div> */}
                  <p className={styles.title}>{hit.title}</p>
                  <p className="text-base m-0 p-0">{hit.excerpt}</p>
                  <Link
                    href={hit.slug && hit.slug}
                    className={styles.readMore}
                    prefetch={false}
                  >
                    Read more
                  </Link>
                </article>
              </Link>
            </div>
          )
        }
      })}
    </div>
  )
}
