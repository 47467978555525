import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'

import { urlFor } from '@lib/sanity'
import Image from 'next/image'
import { Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './ShowReel.module.scss'

export default function ShowReel({ data }) {
  const { title, images } = data || {}

  return (
    <div className={styles.wrapper}>
      {title && <h2>{title}</h2>}
      <div className={styles.showreel}>
        {images && (
          <Swiper
            slidesPerView={1}
            modules={[Autoplay, EffectFade]}
            effect={'fade'}
            fadeEffect={{ crossFade: true }}
            speed={100}
            autoplay={{
              delay: 225,
              disableOnInteraction: false,
            }}
            spaceBetween={0}
            allowTouchMove={false}
            loop={true}
          >
            {images.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className={styles.image}>
                    <Image
                      src={urlFor(image.asset)
                        .crop('focalpoint')
                        .fit('clip')
                        .quality(95)
                        .width(1200)
                        .dpr(1.2)
                        .sharpen(12)
                        .format('webp')
                        .url()}
                      alt={image.alt}
                      fill
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
    </div>
  )
}
