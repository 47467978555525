import 'swiper/css'

// import 'swiper/css/pagination'
import Stars from '@components/Stars'
import { A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './HeroReviews.module.scss'

export default function HeroReviews({ data }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.desktop}>
        {data?.map(
          (review, key) => key < 3 && <Review key={key} data={review} />
        )}
      </div>
      <div className={styles.mobile}>
        <Swiper
          modules={[A11y, Autoplay]}
          autoplay={{ delay: 3750 }}
          speed={1500}
          loop={true}
          slidesPerView={1}
        >
          <>
            {data?.map((review, key) => (
              <SwiperSlide key={key}>
                <Review data={review} />
              </SwiperSlide>
            ))}
          </>
        </Swiper>
      </div>
    </div>
  )
}

const Review = ({ data }) => {
  return (
    <div className={styles.review}>
      <Stars colour={'#000'} count={data?.count} />
      <p>{data?.content}</p>
    </div>
  )
}
