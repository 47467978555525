/*
================================================================================================================================================
  ______                                 __  __  __                            ______                                                                                                                                                                                                                                                                                                                                           
 /      \                               /  |/  |/  |                          /      |                                                                                                                                                                                                                                                                                                                                          
/$$$$$$  |  _______   ______    ______  $$ |$$ |$$/  _______    ______        $$$$$$/  _____  ____    ______    ______    ______    _______                                                                                                                                                                                                                                                                                     
$$ \__$$/  /       | /      \  /      \ $$ |$$ |/  |/       \  /      \         $$ |  /     \/    \  /      \  /      \  /      \  /       |                                                                                                                                                                                                                                                                                    
$$      \ /$$$$$$$/ /$$$$$$  |/$$$$$$  |$$ |$$ |$$ |$$$$$$$  |/$$$$$$  |        $$ |  $$$$$$ $$$$  | $$$$$$  |/$$$$$$  |/$$$$$$  |/$$$$$$$/                                                                                                                                                                                                                                                                                     
 $$$$$$  |$$ |      $$ |  $$/ $$ |  $$ |$$ |$$ |$$ |$$ |  $$ |$$ |  $$ |        $$ |  $$ | $$ | $$ | /    $$ |$$ |  $$ |$$    $$ |$$      \                                                                                                                                                                                                                                                                                     
/  \__$$ |$$ \_____ $$ |      $$ \__$$ |$$ |$$ |$$ |$$ |  $$ |$$ \__$$ |       _$$ |_ $$ | $$ | $$ |/$$$$$$$ |$$ \__$$ |$$$$$$$$/  $$$$$$  |                                                                                                                                                                                                                                                                                    
$$    $$/ $$       |$$ |      $$    $$/ $$ |$$ |$$ |$$ |  $$ |$$    $$ |      / $$   |$$ | $$ | $$ |$$    $$ |$$    $$ |$$       |/     $$/                                                                                                                                                                                                                                                                                     
 $$$$$$/   $$$$$$$/ $$/        $$$$$$/  $$/ $$/ $$/ $$/   $$/  $$$$$$$ |      $$$$$$/ $$/  $$/  $$/  $$$$$$$/  $$$$$$$ | $$$$$$$/ $$$$$$$/                                                                                                                                                                                                                                                                                      
                                                              /  \__$$ |                                      /  \__$$ |                                                                                                                                                                                                                                                                                                        
                                                              $$    $$/                                       $$    $$/                                                                                                                                                                                                                                                                                                         
                                                               $$$$$$/                                         $$$$$$/                                                                                                                                                                                                                                                                                                          
                             
Renders a scrolling images block from the page builder                                 
===============================================================================================================================================

*/
import { TextPortable, urlFor } from '@lib/sanity'
import Image from 'next/image'

import styles from './ScrollingImages.module.scss'

const ScrollingImages = ({ data, colourTheme,}) => {
  const {
    title,
    desc,
    imageContentTop,
    imageContentBottom,
    scrollDuration,
    theme,
    type,
    id
  } = data || {}
  
  const getTopRow = () => {
    return imageContentTop ? (
      //   return list of images
      <>
      <ul
        className={`${styles.marqueeContent} ${type == 'vertical' && styles.marqueeVertical} ${styles[`duration${scrollDuration.toString()}`]}`}
        id="scroller-top"
        data-duration={scrollDuration.toString()}
        >
        {imageContentTop.map((image, i) => {
          return (
            <li key={i} className={`${type == 'horizontalLarge' ? 'aspect-[16/12] relative flex-[1] min-h-[260px] lg:min-h-[640px] md:min-h-[370px]' : 'relative aspect-square w-full h-auto'}`}>
              <Image
                src={urlFor(image.asset).quality(75).format('webp').url()}
                alt="test"
                fill
                sizes={
                  '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
                }
                className='object-cover'
              />
            </li>
          )
        })}
      </ul>
    
      </>
    ) : (
      // if no images add a placeholder
      <ul className={styles.topRow} id="scroller-top">
        <li className={`${styles.ration} ${styles.ration__10_15}`}>
          <img src={_placeholder} alt="test" />
        </li>

        <li className={`${styles.ration} ${styles.ration__10_15}`}>
          <img src={_placeholder} alt="test" />
        </li>

        <li className={`${styles.ration} ${styles.ration__10_15}`}>
          <img src={_placeholder} alt="test" />
        </li>

        <li className={`${styles.ration} ${styles.ration__10_15}`}>
          <img src={_placeholder} alt="test" />
        </li>
      </ul>
    )
  }

  const getTopRowDup = () => {
    return <>
    <ul
      className={`${styles.marqueeContent} ${type == 'vertical' && styles.marqueeVertical} ${styles[`duration${scrollDuration.toString()}`]}`}
      id="scroller-top"
      aria-hidden="true"
      data-duration={scrollDuration.toString()}
      >
      {imageContentTop.map((image, i) => {
        return (
          <li key={i} className={`${type == 'horizontalLarge' ? 'aspect-[16/12] relative flex-[1] min-h-[260px] lg:min-h-[640px] md:min-h-[370px]' : 'relative aspect-square w-full h-auto'}`}>
          <Image
              src={urlFor(image.asset).quality(75).format('webp').url()}
              alt="test"
              fill
              sizes={
                '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
              }
              className='object-cover'
            />
          </li>
        )
      })}
    </ul>

    </>
  }

  const getBottomRow = () => {
    return imageContentBottom ? (
      //   return list of images
      <ul
      className={`${styles.marqueeContent} ${styles.marqueeContentRev} ${type == 'vertical' && styles.marqueeVertical} ${styles[`duration${scrollDuration.toString()}`]}`}
      id="scroller-bottom"
      data-duration={scrollDuration.toString()}
      >
        {imageContentBottom.map((image, i) => {
          return (
            <li key={i} className={`${type == 'horizontalLarge' ? 'aspect-[16/12] relative flex-[1] min-h-[260px] lg:min-h-[640px] md:min-h-[370px]' : 'relative aspect-square w-full h-auto'}`}>
            <Image
              src={urlFor(image.asset).quality(75).format('webp').url()}
              alt="test"
              fill
              sizes={
                '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
              }
              className='object-cover'
            />
          </li>
          )
        })}
      </ul>
    ) : (
      // if no images add a placeholder
      <ul className={styles.bottomRow} id="scroller-bottom">
        <li className={`${styles.ration} ${styles.ration__10_15}`}>
          <img src={_placeholder} alt="test" />
        </li>

        <li className={`${styles.ration} ${styles.ration__10_15}`}>
          <img src={_placeholder} alt="test" />
        </li>

        <li className={`${styles.ration} ${styles.ration__10_15}`}>
          <img src={_placeholder} alt="test" />
        </li>

        <li className={`${styles.ration} ${styles.ration__10_15}`}>
          <img src={_placeholder} alt="test" />
        </li>
      </ul>
    )
  }


  const getBottomRowDup = () => {
    return   <ul
    className={`${styles.marqueeContent} ${styles.marqueeContentRev} ${type == 'vertical' && styles.marqueeVertical} ${styles[`duration${scrollDuration.toString()}`]}`}
    id="scroller-bottom"
    aria-hidden="true"
    data-duration={scrollDuration.toString()}
  >
    {imageContentBottom.map((image, i) => {
      return (
        <li key={i} className={`${type == 'horizontalLarge' ? 'aspect-[16/12] relative flex-[1] min-h-[260px] lg:min-h-[640px] md:min-h-[370px]' : 'relative aspect-square w-full h-auto'}`}>
        <Image
          src={urlFor(image.asset).quality(75).format('webp').url()}
          alt="test"
          fill
          sizes={
            '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
          }
          className='object-cover'
        />
      </li>
      )
    })}
  </ul>
  }
  // const handleScroll = () => {
  //   const position = window.pageYOffset
  //   setScrollPosition(position)
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true })

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  // useEffect(() => {
  //   const scrollerTop = document.querySelectorAll(`#scroller-top`)
  //   const scrollerBottom = document.querySelectorAll(`#scroller-bottom`)
  //   // let rectTop = scrollerTop.getBoundingClientRect()
  //   // let rectBottom = scrollerBottom.getBoundingClientRect()
  //   scrollerTop.forEach((s)=>{
  //     s.style.left = scrollPosition / 6 + 'px'
  //   })
  //   scrollerBottom.forEach((s)=>{
  //     s.style.right = scrollPosition / 6 + 'px'
  //   })
  //   // scrollerTop.style.left = scrollPosition / 6 + 'px'
  //   // scrollerBottom.style.right = scrollPosition / 6 + 'px'
  // }, [scrollPosition])

  return (
    <div
      className={`${styles.wrapper} ${styles[theme]} ${type == "vertical"  && styles.across}`}
      data-theme={theme ? theme : 'light'}
      style={{background : theme == 'inherit' && colourTheme }}
    >
        <div>
           {title && <h2>{title}</h2>}
           {desc && (
              <div className={styles.description}>
                <TextPortable value={desc} />
              </div>
           )}
        </div>
     
      <section className={`${styles.container} mb-20 scroller-container`} id={id}>
        <div className={`${styles.imageContainer} ${styles[type]} ${styles.marquee} ${type == "vertical" && styles.vHeight}`}>
           <>
           <div className={`${type == "vertical" ? `flex flex-row mb-4` : `flex`}`}>
          {getTopRow()}
          {getTopRowDup()}
          </div>
          <div className={`${type == "vertical" ? `flex flex-row` : `flex`}`}>
          {getBottomRow()}
          {getBottomRowDup()}
          </div> 
          </>
               </div>
      </section>
    </div>
  )
}

export default ScrollingImages
