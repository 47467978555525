import 'swiper/css'

import CTA from '@components/CTA'
import ReviewCard from '@components/ReviewCard'
import addBreak from '@utils/addBreak'
import { useEffect, useState } from 'react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './Reviews.module.scss'

const Reviews = ({ data }) => {
  const [_reviews, setReviews] = useState('')
  const { reviews, title, description, theme, cta } = data || {}
  const { isEnabled, theme: _theme } = cta || {}

  const getReviews = () => {
    const listReviews = []
    reviews.map((r, i) => {
      listReviews.push(
        <SwiperSlide key={i} className={styles.slide}>
          <ReviewCard review={r} />
        </SwiperSlide>
      )
    })
    setReviews(listReviews)
  }

  useEffect(() => {
    if (!_reviews) {
      getReviews()
    }
  }, [])

  return (
    <div
      className={`${styles.wrapper} ${styles[theme]}`}
      data-theme={theme ? theme : 'light'}
    >
      <section className={styles.container}>
        <div className={styles.body}>
          {title && <h2>{addBreak(title, 3)}</h2>}
          {description && <p className={styles.desc}>{description}</p>}
        </div>

        <Swiper
          className={styles.swiperContainer}
          modules={[Autoplay]}
          autoplay={{ delay: 3750 }}
          autoHeight={true}
          speed={1500}
          slidesPerView={1}
          spaceBetween={60}
          loop={true}
          lazy={'true'}
          centeredSlides={true}
          freeMode={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 60,
              centeredSlides: false,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 60,
              centeredSlides: true,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 80,
              centeredSlides: false,
            },
            1680: {
              slidesPerView: 4,
              spaceBetween: 100,
              centeredSlides: false,
            },
          }}
        >
          {_reviews}
        </Swiper>
        {isEnabled && (
          <div className="flex w-full justify-center mt-10">
            <CTA content={cta} opt={_theme} />
          </div>
        )}
      </section>
    </div>
  )
}

export default Reviews
