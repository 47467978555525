export default function emojiReplace(str) {
  const emojiMap = {
    ':pray:': '🙏',
    ':smile:': '😄',
    ':raised_hands:': '🙌',
    ':heart_eyes:': '😍',
    ':sunglasses:': '😎',
    ':100:': '💯',
    ':muscle:': '💪',
    ':fire:': '🔥',
    ':ok_hand:': '👌',
    ':clap:': '👏',
    ':star:': '⭐',
    ':star2:': '🌟',
    ':star_struck:': '🤩',
    ':sparkles:': '✨',
    ':sparkling_heart:': '💖',
    ':heart:': '❤️',
    ':heart_eyes_cat:': '😻',
    ':thumbsup:': '👍',
    ':thumbsdown:': '👎',
  }

  return str.replace(/:pray:/g, (match) => emojiMap[match])
}
