/*
████████╗███████╗ █████╗ ███╗   ███╗
╚══██╔══╝██╔════╝██╔══██╗████╗ ████║
   ██║   █████╗  ███████║██╔████╔██║
   ██║   ██╔══╝  ██╔══██║██║╚██╔╝██║
   ██║   ███████╗██║  ██║██║ ╚═╝ ██║
   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝
 ██████╗ █████╗ ██████╗ ██████╗ ███████╗
██╔════╝██╔══██╗██╔══██╗██╔══██╗██╔════╝
██║     ███████║██████╔╝██║  ██║███████╗
██║     ██╔══██║██╔══██╗██║  ██║╚════██║
╚██████╗██║  ██║██║  ██║██████╔╝███████║
 ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝
*/
// Renders the team cards block from the page builder component

import TeamCard from '@components/TeamCard'
import { useAppContext } from '@hooks/useAppContext'
import addBreak from '@utils/addBreak'

// import dynamic from 'next/dynamic'
import styles from './TeamCards.module.scss'

// const TeamCard = dynamic(() => import('@components/TeamCard'), { ssr: false })

const TeamCards = ({ data }) => {
  const { globals } = useAppContext()
  const { site } = globals || {}
  const { team } = site || {}

  const { title, desc, theme } = data || {}

  return (
    <section
      className={`${styles.wrapper} ${theme ? styles[theme] : styles.dark}`}
      data-theme={theme ? theme : 'dark'}
    >
      <div className={styles.container}>
        {title && <h2>{addBreak(title, 2)}</h2>}
        {desc && <p className={styles.desc}>{desc}</p>}

        <div className={styles.teamGrid}>
          {team.map((t, i) => {
            return (
              <div key={i}>
                <TeamCard content={t} />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default TeamCards
