/*
 ______                                                     ___            ________                     __     
/      |                                                   /   \          /        |                   /  |    
$$$$$$/  _____  ____    ______    ______    ______        /$$$  |         $$$$$$$$/______   __    __  _$$ |_   
  $$ |  /     \/    \  /      \  /      \  /      \       $$ $$ \__          $$ | /      \ /  \  /  |/ $$   |  
  $$ |  $$$$$$ $$$$  | $$$$$$  |/$$$$$$  |/$$$$$$  |      /$$$     |         $$ |/$$$$$$  |$$  \/$$/ $$$$$$/   
  $$ |  $$ | $$ | $$ | /    $$ |$$ |  $$ |$$    $$ |      $$ $$ $$/          $$ |$$    $$ | $$  $$<    $$ | __ 
 _$$ |_ $$ | $$ | $$ |/$$$$$$$ |$$ \__$$ |$$$$$$$$/       $$ \$$  \          $$ |$$$$$$$$/  /$$$$  \   $$ |/  |
/ $$   |$$ | $$ | $$ |$$    $$ |$$    $$ |$$       |      $$   $$  |         $$ |$$       |/$$/ $$  |  $$  $$/ 
$$$$$$/ $$/  $$/  $$/  $$$$$$$/  $$$$$$$ | $$$$$$$/        $$$$/$$/          $$/  $$$$$$$/ $$/   $$/    $$$$/  
                                /  \__$$ |                                                                     
                                $$    $$/                                                                      
                                 $$$$$$/       

Render the image & text block from the page builder
*/

import { TextPortable, urlFor } from '@lib/sanity'

// import Image from 'next/image'
import styles from './ImageTextBlock.module.scss'

const ImageTextBlock = ({ image, text, data }) => {
  const { theme, imagePlacement } = data || {}


  return (
    <section
      className={`${styles.wrapper} ${styles[theme]} ${styles[imagePlacement]}`}
      data-theme={theme ? theme : 'light'}
    >
      <div className={`${styles.container} ${styles[imagePlacement]}`}>
      {image && (
        <div className={styles.image}>
          <img
            src={urlFor(image.asset).quality(75).format('webp').url()}
            alt={image.alt}
          />
        </div>
      )}
      {text && (
        <div className={styles.text}>
          <TextPortable blocks={text} />
        </div>
      )}
      </div>
    </section>
  )
}

export default ImageTextBlock
