/*
=================================================================================================================================================
_______                                                   __            ________                      __                                         
/       \                                                 /  |          /        |                    /  |                                        
$$$$$$$  | ______    ______      __   ______    _______  _$$ |_         $$$$$$$$/  __    __   ______  $$ |  ______    ______    ______    ______  
$$ |__$$ |/      \  /      \    /  | /      \  /       |/ $$   |        $$ |__    /  \  /  | /      \ $$ | /      \  /      \  /      \  /      \ 
$$    $$//$$$$$$  |/$$$$$$  |   $$/ /$$$$$$  |/$$$$$$$/ $$$$$$/         $$    |   $$  \/$$/ /$$$$$$  |$$ |/$$$$$$  |/$$$$$$  |/$$$$$$  |/$$$$$$  |
$$$$$$$/ $$ |  $$/ $$ |  $$ |   /  |$$    $$ |$$ |        $$ | __       $$$$$/     $$  $$<  $$ |  $$ |$$ |$$ |  $$ |$$ |  $$/ $$    $$ |$$ |  $$/ 
$$ |     $$ |      $$ \__$$ |   $$ |$$$$$$$$/ $$ \_____   $$ |/  |      $$ |_____  /$$$$  \ $$ |__$$ |$$ |$$ \__$$ |$$ |      $$$$$$$$/ $$ |      
$$ |     $$ |      $$    $$/    $$ |$$       |$$       |  $$  $$/       $$       |/$$/ $$  |$$    $$/ $$ |$$    $$/ $$ |      $$       |$$ |      
$$/      $$/        $$$$$$/__   $$ | $$$$$$$/  $$$$$$$/    $$$$/        $$$$$$$$/ $$/   $$/ $$$$$$$/  $$/  $$$$$$/  $$/        $$$$$$$/ $$/       
                          /  \__$$ |                                                        $$ |                                                  
                          $$    $$/                                                         $$ |                                                  
                           $$$$$$/                                                          $$/   
            
Renders the project explorer table from the page builder
=================================================================================================================================================
*/
import styles from '@blocks/ProjectExplorer.module.scss'
import Button from '@components/Button'
import { urlFor } from '@lib/sanity'
import Link from 'next/link'
import { useRouter } from 'next/router'

const ProjectExplorer = ({ projects, data }) => {
  const { sideContent, theme } = data || {}
  // const { cta, sideTitle: title, sideDesc: desc } = sideContent || {}
  const router = useRouter()

  const title = 'Explore'
  const body =
    "We're honest, keep things simple, and try new things. We listen carefully to our clients to craft websites that stand out and that people love to use. Agencies and businesses come to us to help them design, develop, and maintain their websites."

  return (
    <div className={styles.exploreWrapper} data-theme={theme ? theme : 'light'}>
      {sideContent && (
        <div className={styles.exploreInfo}>
          {title && <h4>{title}</h4>}
          {/* <h4>Explore</h4> */}
          {desc && <div className={`${styles.desc}`}>{desc}</div>}
          {/* <p className={styles.desc}>
              We're honest, keep things simple, and try new things. We listen
              carefully to our clients to craft websites that stand out and that
              people love to use. Agencies and businesses come to us to help them
              design, develop, and maintain their websites.{' '}
            </p> */}
          {cta && (
            <Link href="/" title={cta.ctaText} prefetch={false}>
              {theme == 'Light' ? (
                <Button
                  type="button"
                  text={cta.ctaText}
                  scrolling
                  opt={'dark'}
                />
              ) : (
                <Button
                  type="button"
                  text={cta.ctaText}
                  scrolling
                  opt={'light'}
                />
              )}
            </Link>
          )}
          {/* <Button type="button" text="We’re hiring 👍" scrolling opt={'light'} /> */}
        </div>
      )}

      {router.pathname == '/' ? (
        <div className={styles.exploreInfo}>
          {title && <h4>{title}</h4>}
          {body && <p className={styles.desc}>{body}</p>}
          <Button
            type="button"
            text="We're hiring 👍"
            scrolling
            opt={'light'}
          />
        </div>
      ) : null}

      <div className={`${styles.wrapper}`}>
        {data
          ? data.projects.map((project, i) => (
              <Link
                key={i}
                href={`/work/${project.slug.current}`}
                title={project.client}
              >
                <div
                  key={i}
                  className={`${styles.expProject} ${
                    theme == 'Light' ? '' : 'light'
                  }`}
                >
                  <div className={styles.expClient}>{project.client}</div>
                  <div className={styles.expArrow}>🡒</div>
                  <div className={styles.expImage}>
                    <img
                      src={urlFor(project.thumb.asset).url()}
                      alt={project.client}
                    />
                  </div>
                </div>
              </Link>
            ))
          : projects.map((project, i) => (
              <Link
                key={i}
                href={`/work/${project.slug.current}`}
                title={project.client}
              >
                <div key={i} className={styles.expProject}>
                  <div className={styles.expClient}>{project.client}</div>
                  <div className={styles.expArrow}>🡒</div>
                  <div className={styles.expImage}>
                    <img
                      src={urlFor(project.thumb.asset).url()}
                      alt={project.client}
                    />
                  </div>
                </div>
              </Link>
            ))}
      </div>
    </div>
  )
}

export default ProjectExplorer
