import { urlFor } from '@lib/sanity'
import Image from 'next/image'

import styles from './ImageGrid.module.scss'

export default function ImageBlock({ data, colourTheme, colourText }) {
  const {
    imageGridBottomLeft: bottomLeft,
    imageGridBottomRight: bottomRight,
    imageGridTopLeft: topLeft,
    imageGridTopRight: topRight,
  } = data || {}

  const images = [topLeft, topRight, bottomLeft, bottomRight]

  return (
    <section
      className={styles.wrapper}
      data-theme={
        colourText && colourText == 'light'
          ? 'dark'
          : colourText && colourText == 'dark'
          ? 'light'
          : 'light'
      }
      style={{ background: colourTheme }}
    >
      <div className={styles.container}>
        <div className={styles.imgGrid}>
          {images.map((image, i) => {
            return (
              <div key={i}>
                <ImageItem image={image.asset} alt={image.alt} />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

const ImageItem = ({ image, alt }) => {
  return (
    <Image
      src={urlFor(image)
        .crop('entropy')
        .fit('scale')
        .height(600)
        .quality(100)
        .sharpen(12)
        .dpr(1)
        .format('webp')
        .url()}
      alt={alt}
      fill
    />
  )
}
