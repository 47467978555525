import algoliasearch from 'algoliasearch/lite'
import Link from 'next/link'
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web'

import { Pagination } from './Pagination'
import { Results } from './Results'
import styles from './Search.module.scss'
import UtilityBar from './UtilityBar'

export default function Search({ perPage, signupLocation, animateOnScroll }) {
  const searchClient = algoliasearch(
    'ID0OWXSZ6D',
    '751cd17e6f3c7d4f5b752a6f82d2ade5'
  )

  return (
    <div className={styles.container}>
      <InstantSearch searchClient={searchClient} indexName="posts_DATEDESC">
        <Configure hitsPerPage={perPage || 12} />

        {/* utility bar start */}
        <UtilityBar filterBy="tags" />
        {/* utility bar end */}

        {/* Hits */}
        <div className="mt-8">
          <Results
            signupLocation={signupLocation}
            perPage={perPage}
            animateOnScroll={animateOnScroll}
          />
        </div>
        {/* End hits */}
        <Pagination />
        <Link href="/insights/all">
         <p className='text-center text-[grey] mt-6'>View all posts</p>
        </Link>
      </InstantSearch>
    </div>
  )
}
