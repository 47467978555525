import Search from '@components/search/Search'

import styles from './ArticleFeed.module.scss'

const ArticleFeed = ({ data }) => {
  const { isEnabled, perPage, signupLocation, animateOnScroll } = data || {}

  // Search Index
  const searchIndices = [{ name: `prod_KARMOON`, title: `Karmoon Articles` }]
  return (
    <div className={styles.container}>
      {isEnabled ? (
        <Search
          perPage={perPage}
          indices={searchIndices}
          signupLocation={signupLocation}
          animateOnScroll={animateOnScroll}
        />
      ) : (
        <p className="text-dark">Must be enabled</p>
      )}
    </div>
  )
}

export default ArticleFeed
