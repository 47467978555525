import { TextPortable } from '@lib/sanity'
import { useRef, useState } from 'react'

import styles from './AccordionItem.module.scss'
import { LongArrow } from './Icons'

const AccordionItem = ({ data }) => {
  const [clicked, setClicked] = useState(false)
  const contentEl = useRef()

  const { accordionTab, accordionContent } = data

  const handleToggle = () => {
    setClicked((prev) => !prev)
  }

  return (
    <div className={`${styles.wrapper} ${clicked ? styles.active : ''}`}>
      <div className={styles.tab} onClick={handleToggle}>
        {/* <span>{icon}</span> */}
        {accordionTab}
        <span className={styles.arrow}><LongArrow /></span>
      </div>

      <div
        ref={contentEl}
        className={styles.content_wrapper}
        style={
          clicked
            ? { height: contentEl.current.scrollHeight }
            : { height: '0px' }
        }
      >
        <div className={styles.content}>
          <TextPortable blocks={accordionContent} />
        </div>
      </div>
    </div>
  )
}

export default AccordionItem
