import Link from 'next/link'
import PropTypes from 'prop-types'

import Button from './Button'

const CTA = ({ content, opt }) => {
  const { type, link, text } = content || {}
  let linked = false

  if (link?.internalUrl?.url || link?.externalUrl?.url) {
    linked = true
  }

  // console.log('CTA: ', content)

  switch (linked) {
    case true:
      return <Linked type={type} text={text} link={link} opt={opt} />
    case false:
      return <Output type={type} text={text} opt={opt} />
    default:
      return null
  }
}

const Linked = ({ type, text, link, opt }) => {
  if (link?.internalUrl) {
    return (
      <Link href={link?.internalUrl.url} title={text} prefetch={false} className={`${opt == 'light' ? 'text-light' : 'text-dark'}`}>
        <Output type={type} text={text} opt={opt} />
      </Link>
    )
  } else if (link?.externalUrl) {
    return (
      <a href={link?.externalUrl.url} title={text} className={`${opt == 'light' ? 'text-light' : 'text-dark'}`}>
        <Output type={type} text={text} opt={opt} />
      </a>
    )
  }
}

const Output = ({ type, text, opt }) => {
  switch (type) {
    case 'button':
      return <Button text={text} opt={opt || 'dark'} />
    case 'scrollingButton':
      return <Button text={text} opt={opt || 'dark'} scrolling />
    case 'text':
      return <span className={opt == "light" ? 'text-white' : 'text-dark'}>{text}</span>
    default:
      return false
  }
}

CTA.propTypes = {
  content: PropTypes.object,
}

export default CTA
