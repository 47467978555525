/*
████████╗███████╗██╗  ██╗████████╗
╚══██╔══╝██╔════╝╚██╗██╔╝╚══██╔══╝
   ██║   █████╗   ╚███╔╝    ██║   
   ██║   ██╔══╝   ██╔██╗    ██║   
   ██║   ███████╗██╔╝ ██╗   ██║   
   ╚═╝   ╚══════╝╚═╝  ╚═╝   ╚═╝   
 ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗███████╗
██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║██╔════╝
██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║███████╗
██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║╚════██║
╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║███████║
 ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝╚══════╝
*/
// Renders the text columns block from the page builder

import CTA from '@components/CTA'
import { TextPortable } from '@lib/sanity'

import styles from './TextColumns.module.scss'

const TextColumns = ({ data }) => {
  const { title, theme, cta } = data || {}
  const { textLeft, textRight } = data || {}
  const { isEnabled, theme: _theme } = cta || {}

  return (
    <section
      className={`${styles.wrapper} ${styles[theme]}`}
      data-theme={theme ? theme : 'light'}
    >
      <div className={styles.container}>
        {title && <h4>{title}</h4>}
        <div className={`${styles.inner}`}>
          {textLeft && (
            <div>
              <TextPortable blocks={textLeft} />
            </div>
          )}
          {textRight && (
            <div>
              <TextPortable blocks={textRight} />
            </div>
          )}
        </div>
        {isEnabled && <CTA content={cta} opt={_theme} />}
      </div>
    </section>
  )
}

export default TextColumns
