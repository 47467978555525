/* eslint-disable no-unused-vars */
/*
=======================================================================================
________                     __            _______   __                      __       
/        |                   /  |          /       \ /  |                    /  |      
$$$$$$$$/______   __    __  _$$ |_         $$$$$$$  |$$ |  ______    _______ $$ |   __ 
   $$ | /      \ /  \  /  |/ $$   |        $$ |__$$ |$$ | /      \  /       |$$ |  /  |
   $$ |/$$$$$$  |$$  \/$$/ $$$$$$/         $$    $$< $$ |/$$$$$$  |/$$$$$$$/ $$ |_/$$/ 
   $$ |$$    $$ | $$  $$<    $$ | __       $$$$$$$  |$$ |$$ |  $$ |$$ |      $$   $$<  
   $$ |$$$$$$$$/  /$$$$  \   $$ |/  |      $$ |__$$ |$$ |$$ \__$$ |$$ \_____ $$$$$$  \ 
   $$ |$$       |/$$/ $$  |  $$  $$/       $$    $$/ $$ |$$    $$/ $$       |$$ | $$  |
   $$/  $$$$$$$/ $$/   $$/    $$$$/        $$$$$$$/  $$/  $$$$$$/   $$$$$$$/ $$/   $$/ 

Renders a text block from the page builder component
=======================================================================================
*/

import { TextPortable } from '@lib/sanity'
import Image from 'next/image'

import styles from './TextBlock.module.scss'

const TextBlock = ({ data, text }) => {
  const { isSingleCol, theme } = data || {}
  // console.log(data)

  if (isSingleCol) {
    return (
      <div
        className={`${styles.singleCol} ${styles[theme]}`}
        data-theme={theme ? theme : 'light'}
      >
        <section>{text && <TextPortable blocks={text} />}</section>
      </div>
    )
  } else {
    return (
      <section
        className={`${styles.wrapper} ${styles[theme]} mx-4 md:mx-20`}
        data-theme={theme ? theme : 'light'}
      >
        {text && <TextPortable blocks={text} />}
      </section>
    )
  }
}

export default TextBlock
