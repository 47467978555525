import styles from '@blocks/Calendly.module.scss'
import CTA from '@components/CTA'
import { useAppContext } from '@hooks/useAppContext'
// import Image from 'next/image'
import { InlineWidget } from 'react-calendly'

const Calendly = ({ data }) => {
  const { globals } = useAppContext()
  const { isEnabled, title, desc, theme, cta } = data || {}
  const { social } = globals.site
  const { calendly } = social || {}

  return isEnabled ? (
    <div
      className={`${styles.container} ${styles[theme]}`}
      data-theme={theme ? theme : 'dark'}
    >
      {title && <h2>{title}</h2>}
      {desc && <div className={styles.description}>{desc}</div>}
      <div className={styles.calendar}>
        <InlineWidget
          styles={{ height: '100%', overflow: 'hidden', minHeight: '685px' }}
          url={`${calendly}?hide_event_type_details=1&hide_gdpr_banner=1`}
        />
       {/* <div className='absolute right-[100px] bottom-[-25px]'>
        <Image src="/img/bagman-local.png" alt="karmoon" width={350} height={138} />
      </div> */}
      </div>
      {cta && <div className='flex items-center justify-center mt-6'><CTA content={cta} opt={cta.theme} /></div>}
    </div>
  ) : null
}

export default Calendly
