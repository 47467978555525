import PropTypes from 'prop-types'

import { Star } from './Icons'

export default function Stars({ count, colour }) {
  return (
    <div className="flex flex-row gap-1 justify-center items-center">
      {Array(count)
        .fill()
        .map((_, i) => (
          <Star key={i} colour={colour || '#fff'} />
        ))}
    </div>
  )
}

Stars.propTypes = {
  count: PropTypes.number,
  colour: PropTypes.string,
}
