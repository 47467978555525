/*
====================================================================================
 ______                                                 __  __                     
 /      \                                               /  |/  |                    
/$$$$$$  |  _______   _______   ______    ______    ____$$ |$$/   ______   _______  
$$ |__$$ | /       | /       | /      \  /      \  /    $$ |/  | /      \ /       \ 
$$    $$ |/$$$$$$$/ /$$$$$$$/ /$$$$$$  |/$$$$$$  |/$$$$$$$ |$$ |/$$$$$$  |$$$$$$$  |
$$$$$$$$ |$$ |      $$ |      $$ |  $$ |$$ |  $$/ $$ |  $$ |$$ |$$ |  $$ |$$ |  $$ |
$$ |  $$ |$$ \_____ $$ \_____ $$ \__$$ |$$ |      $$ \__$$ |$$ |$$ \__$$ |$$ |  $$ |
$$ |  $$ |$$       |$$       |$$    $$/ $$ |      $$    $$ |$$ |$$    $$/ $$ |  $$ |
$$/   $$/  $$$$$$$/  $$$$$$$/  $$$$$$/  $$/        $$$$$$$/ $$/  $$$$$$/  $$/   $$/ 

Renders the 'Accordion' page builder block type
====================================================================================
*/

import styles from '@blocks/Accordion.module.scss'
import AccordionItem from '@components/AccordionItem'
import Button from '@components/Button'
import CTA from '@components/CTA'
import { TextPortable } from '@lib/sanity'
import Link from 'next/link'

const Accordion = ({ data }) => {
  const { title, optionalSideSection, theme, accordionContent } = data || {}
  const { link, sideTitle, sideDescription } = optionalSideSection || {}
  const { text: linkText, externalUrl, internalUrl, button } = link || {}
  // console.log(data)

  return (
    <div
      className={`${styles.wrapper} ${styles[theme]}`}
      data-theme={theme ? theme : 'light'}
    >
      <section className={styles.container}>
        {title && <h2>{title}</h2>}
        <div className={styles.accordionWrapper}>
          <div className={styles.panel}>
            {sideTitle && <h3>{sideTitle}</h3>}
            {sideDescription && (
              <div className={styles.desc}>
                <TextPortable blocks={sideDescription} />
              </div>
            )}

            {link && (
              <>
                {externalUrl?.url && (
                  <a href={`${externalUrl}?ref=karmoon`}>
                    <Button text={linkText} opt={'dark'} />
                  </a>
                )}
                {internalUrl?.url && (
                  <Link href={internalUrl.url} title={linkText} prefetch={false}>
                    <CTA type={button} text={linkText} />
                  </Link>
                )}
              </>
            )}
          </div>

          <div
            className={`${styles.accordion} ${
              !optionalSideSection ? styles.slim : ''
            }`}
          >
            {accordionContent &&
              accordionContent.map((item, i) => (
                <AccordionItem key={i} data={item} />
              ))}
          </div>
        </div>
      </section>
    </div>
  )
}
export default Accordion
