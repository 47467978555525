import Stars from '@components/Stars'
import { urlFor } from '@lib/sanity'
import emojiReplace from '@utils/emojiReplace'
import Image from 'next/image'

import styles from './ReviewCard.module.scss'

export default function ReviewCard({ review }) {
  const { image, name, title, company, content, stars } = review
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.headshot}>
          <Image
            src={urlFor(image.asset)
              .crop('entropy')
              .fit('crop')
              .quality(75)
              .sharpen(12)
              .width(80)
              .height(80)
              .dpr(1)
              .format('webp')
              .url()}
            alt={image.alt}
            className={styles.image}
            fill
            sizes={
              '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
            }
          />
        </div>
        <div className={styles.details}>
          <p className={styles.name}>{name}</p>
          <p className={`text-xs m-0 p-0 font-light`}>{title}</p>
            <p className={styles.review}>{emojiReplace(content)}</p>
          <p className={styles.company}>{company}</p>
          <Stars colour={'#000'} count={stars} />
        </div>
      </div>
    </div>
  )
}
