import { TextPortable } from '@lib/sanity'
import { useRef, useState } from 'react'

import { LongArrow } from '../components/Icons'
import styles from './FAQ.module.scss'


const FAQ = ({data}) => {

    const {theme, title, description, items} = data || {}
    return (
        <div  className={`${styles.wrapper} ${styles[theme]}`}
        data-theme={theme ? theme : 'light'}>
            {title && <h3 className={styles.title}>{title}</h3> }
            {description && <div className={styles.description}><TextPortable value={description} /></div>}

            <div className={styles.faqArea}>
                {items &&
                    items.map((item,i)=>{
                        return <FAQItem data={item} key={i} />
                    })
                }
            </div>
        </div>
    )
}


const FAQItem = (data) => {

    const [clicked, setClicked] = useState(false)
    const contentEl = useRef()

    const { question, answer } = data.data || {}

    const handleToggle = () => {
        setClicked((prev) => !prev)
      }

    return (
        <div className={`${styles.faqWrapper} ${clicked ? styles.active : ''}`}>
      <div className={styles.tab} onClick={handleToggle}>
        {question}
        <span className={styles.arrow}><LongArrow /></span>
      </div>

      <div
        ref={contentEl}
        className={styles.content_wrapper}
        style={
          clicked
            ? { height: contentEl.current.scrollHeight }
            : { height: '0px' }
        }
      >
        <div className={styles.content}>
          <TextPortable blocks={answer} />
        </div>
      </div>
    </div>
    )
}

export default FAQ