import { TextPortable } from '@lib/sanity'
import getYouTubeId from 'get-youtube-id'
import YouTubeEmbed from 'react-youtube'

import styles from './VideoBlock.module.scss';

const VideoBlock = ({data}) => {

    // console.log(data)

    const {isYoutube, youtubeVideo, link, title, description, bgColour, textColour } = data || {}


    if(isYoutube){
        const id = getYouTubeId(youtubeVideo.url)
        return (
            <div className={`${styles.wrapper} ${styles[textColour]}`}>
                 {title && <h4>{title}</h4>}
                {description && <TextPortable blocks={description} />}
                <YouTubeEmbed videoId={id} />
            </div>
        )

    } else {
        return (
            <div className={`${styles.wrapper} ${styles[textColour]}`} style={bgColour?.hex && {background: bgColour.hex}}>
                {title && <h2>{title}</h2>}
                {description && <div className={styles.desc}><TextPortable blocks={description} /></div>}
                <video src={link} controls />
            </div>
        )
    }

  
}

export default VideoBlock;